import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import getConfig from 'next/config';

import {
  type AuthSuccessResult,
  isSSOError,
  signUpGoogle,
} from '@src/apiCalls';
import { primarySignupFormVar } from '@src/apollo/local';
import { SSOButton } from '@src/components/sso-buttons/sso-button';
import { getClickAdIds } from '@src/domains/foundation/tracking/cookies/tracking-cookies';
import { autoFill as squatchAutoFill } from '@src/lib/saasquatch';
import { CookieName, getCookie } from '@src/utils/cookies';

const { GOOGLE_CLIENT_ID } = getConfig().publicRuntimeConfig;

type Props = {
  signup?: boolean;
  signupSource?: string;
  referrerCode?: string;
  signupSourceQualifier?: string;
  viewer?: {
    firstName: string;
    preferredName: string;
    lastName: string;
  };
  onSuccess: (result: AuthSuccessResult) => void;
  onFail?: (error: string) => void;
};

const errorMessage =
  'Could not sign up using Google. Please try signing up with your email instead.';

const GButton = ({ onSuccess, onFail, viewer, ...props }: Props) => {
  const { nurseProfile } = primarySignupFormVar();
  const { gclid, gbraid, wbraid } = getClickAdIds();

  const googleLogin = useGoogleLogin({
    onSuccess: async tokenResponse => {
      try {
        const referralCode = await squatchAutoFill();
        const referringDomain = getCookie(CookieName.ReferringDomain);
        const results = await signUpGoogle({
          signup: props.signup,
          google_access_token: tokenResponse.access_token,
          signup_source: props.signupSource,
          signup_source_qualifier: props.signupSourceQualifier,
          initial_referring_domain: referringDomain,
          referrer_code: props.referrerCode ? [props.referrerCode] : [],
          referral_code: referralCode ?? undefined,
          first_name: viewer?.firstName || '',
          preferred_name: viewer?.preferredName || '',
          last_name: viewer?.lastName || '',
          nurse_type: nurseProfile.nurseType,
          role_id: nurseProfile.roleId,
          gclid,
          gbraid,
          wbraid,
        });

        if (isSSOError(results)) {
          onFail?.(results.sso_error || results.error || errorMessage);
        } else {
          localStorage.setItem('LAST_USED_SSO', 'google');
          onSuccess(results);
        }
      } catch {
        // Usually network errors
        onFail?.(errorMessage);
      }
    },
    onError: error => {
      console.error('Google OAuth error', error);
      return onFail?.(errorMessage);
    },
    onNonOAuthError: data => {
      if (data?.type !== 'popup_closed') {
        onFail?.(errorMessage);
      }
    },
  });

  return <SSOButton provider="google" onClick={() => googleLogin()} />;
};

export const GoogleButton = (props: Props) => {
  if (!GOOGLE_CLIENT_ID) {
    return null;
  }

  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <GButton {...props} />
    </GoogleOAuthProvider>
  );
};
